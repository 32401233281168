<template>
  <div
    class="
      main_content
      client_activity_main
      program_emp_search
      main_client_manager
      align-items-center
    "
    style="min-height: 80vh"
  >
    <div>
      <div class="row justify-content-between mb_40">
        <div class="col-12 col-md-12 col-xl-3 col-xxl-5">
          <div class="heading">
            <h2 class="manager_head">Program Management</h2>
            <h3 class="manager_sub_head">Management Dashboards</h3>
          </div>
        </div>
        <div class="col-12 col-md-12 col-xl-9 col-xxl-7">
          <div class="align-items-center d-flex justify-content-end w-100">
            <div class="date_toolbar_main">
              <div class="bg-white date-toolbar region_bar_add">
                <div class="row me-0">
                  <div class="col-xs-12 col-md-12 col-lg-9 align-self-center">
                    <div class="">
                      <div
                        class="bg-white date-toolbar"
                        style="box-shadow: none"
                      >
                        <div class="row">
                          <div class="col-xs-12 col-md-12 align-self-center">
                            <div class="row">
                              <div class="col-xs-12 col-md-12 col-lg-6">
                                <div class="date">
                                  <div class="heading_5">From</div>
                                  <div
                                    class="
                                      text_black
                                      font_size_14
                                      d-flex
                                      align-items-center
                                    "
                                  >
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17.5"
                                        height="17.5"
                                        viewBox="0 0 17.5 17.5"
                                      >
                                        <path
                                          data-name="Path 131"
                                          d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                          transform="translate(-2.25 -2.25)"
                                          style="
                                            fill: none;
                                            stroke-linecap: round;
                                            stroke-linejoin: round;
                                            stroke-width: 1.5px;
                                          "
                                        />
                                      </svg>
                                    </span>
                                    <datepicker
                                      placeholder="MM DD, YYYY"
                                      v-model="listConfig.start_date"
                                      :format="customFormatter"
                                      @selected="changeStartDate"
                                      :disabledDates="disabledFutureDates"
                                    ></datepicker>
                                    <p class="min-date-value d-none">
                                      {{ listConfig.start_date | formatDate }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xs-12 col-md-12 col-lg-6">
                                <div class="date todate pl_6">
                                  <div class="heading_5">To</div>
                                  <div
                                    class="
                                      text_black
                                      font_size_14
                                      d-flex
                                      align-items-center
                                    "
                                  >
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17.5"
                                        height="17.5"
                                        viewBox="0 0 17.5 17.5"
                                      >
                                        <path
                                          data-name="Path 131"
                                          d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                          transform="translate(-2.25 -2.25)"
                                          style="
                                            fill: none;
                                            stroke-linecap: round;
                                            stroke-linejoin: round;
                                            stroke-width: 1.5px;
                                          "
                                        />
                                      </svg>
                                    </span>
                                    <datepicker
                                      placeholder="MM DD, YYYY"
                                      v-model="listConfig.end_date"
                                      :format="customFormatter"
                                      @selected="changeEndDate"
                                      :disabled="isDisabled"
                                      :disabledDates="disabledDates"
                                    ></datepicker>
                                    <p class="max-date-value d-none">
                                      {{ listConfig.end_date | formatDate }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-12 col-lg-3 align-self-center">
                    <div class="form-group for_ipad">
                      <v-select
                        :reduce="(region_list) => region_list.id"
                        :options="region_list"
                        label="name"
                        placeholder="By Region"
                        name="Region"
                        v-model="listConfig.regionBy"
                        v-validate="'required'"
                        @input="getRegionfilterData"
                        browserAutocomplete="false" 
                        autocomplete="off"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a href="javascript:" class="reset_btn" @click="resetAll">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 1000 1000"
                enable-background="new 0 0 1000 1000"
                xml:space="preserve"
              >
                <metadata>
                  Svg Vector Icons : http://www.onlinewebfonts.com/icon
                </metadata>
                <g>
                  <path
                    d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z"
                  />
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>

      <div class="self_report">
        <div class="row">
          <div class="col-md-12">
            <!-- <div class="white_card border_radius_20"> -->
            <div class="border_radius_20">
              <div class="activity_region py-3 tab_report mb_18 pb_14">
                <div class="row">
                  <div class="col-sm-6 align-self-center">
                    <!-- <h2 class="mb_20">Clients</h2> -->
                  </div>
                  <div class="col-xs-12 col-sm-6 text-center text-sm-end">
                    <div class="d-none visited_pages status_box marg_left my-2">
                      <div class="heading">
                        <div class="form-group status_drop manager_status mb-0">
                          <v-select
                            @input="getClientsBysearch"
                            :reduce="(status_list) => status_list.id"
                            :options="status_list"
                            label="name"
                            placeholder="All Status"
                            name="Status"
                            v-model="listConfig.search_status"
                            v-validate="'required'"
                          />
                          <small class="error text-danger" v-show="errors.has('addUserForm.Status')">
                            {{ errors.first("addUserForm.Status") }}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="tab_report toggle_switch marg_left list_card_switch listANDcard me-0 my-2">
                      <ul id="myTab" role="tablist" class="nav nav-tabs list_tabs">
                        <li role="presentation" class="nav-item list-item">
                          <button id="percent-tab" data-bs-toggle="tab" data-bs-target="#percent" type="button" role="tab" aria-controls="percent" aria-selected="true" class="nav-link list-link active">
                            List
                          </button>
                        </li>
                        <li role="presentation" class="nav-item list-item">
                          <button id="hash-tab" data-bs-toggle="tab" data-bs-target="#hash" type="button" role="tab" aria-controls="hash" aria-selected="false" class="nav-link list-link">
                            Card
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="" style="box-shadow:none;padding:20px 0 !important;">
                      <div id="datatable_wrapper" class="dataTables_wrapper no-footer">
                        <div class="dataTables_length float-none float-lg-start text-center mb-3 mb-lg-0" id="datatable_length">
                          <label>Show
                            <select name="datatable_length" aria-controls="datatable" class=""
                              v-model.number="itemsPerPage" @change="getClientsBysearch">
                              <option>10</option>
                              <option>25</option>
                              <option>50</option>
                              <option>100</option>
                              <option>500</option>
                            </select>
                            entries</label>
                        </div>
                        <div class="align-items-center d-flex justify-content-lg-end justify-content-center">
                          <div id="datatable_filter" class="dataTables_filter mr_mini srch_sml float-none text-center">
                            <label class="float-none"><input type="search" placeholder="Search"
                                aria-controls="datatable" v-model="listConfig.search_string"
                                v-on:keyup.enter="getClientsBysearch" />
                              <a v-if="listConfig.search_string" href="javascript:void(0)" @click="getClientsBysearch" class="search_icon slty_up">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00"
                                  class="bi bi-search" viewBox="0 0 16 16">
                                  <path
                                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                              </a>
                              <a href="javascript:void(0)" v-if="listConfig.search_string" @click="listConfig.search_string = null; getClientsBysearch();" class="crossicon x_icon">
                                X
                              </a>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div id="myTab2Content" class="tab-content">
                        <div id="percent" role="tabpanel" aria-labelledby="#percent" class="table_card tab-pane fade active show">
                          <div class="table_admin mt_50">
                            <div class="col-md-12">
                              <div class="table-responsive table_audit_log clnt_mngr_table px-4">
                                <table class="table table-bordered table dataTable no-footer">
                                  <thead>
                                    <tr>
                                      <th scope="col" class="sort name_clnt">
                                        <SortIcons :lable="'CLIENT NAME'" :listConfig="listConfig" :sortBy="'company_name'" @sorting="sorting($event)" />
                                      </th>
                                      <!-- <th scope="col">Work Area</th> -->
                                      <th class="text-center other_col" scope="col" align="center">Reports</th>
                                      <th class="text-center other_col" scope="col" align="center">Requests</th>
                                      <th class="text-center other_col" scope="col" align="center">Follow-Ups</th>
                                      <!-- <th class="text-center" scope="col" align="center">Status</th> -->
                                      <th class="text-center other_col" scope="col" align="center">ACTION</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(client, indx) in clients_data" :key="indx">
                                      <td>
                                        <router-link class="hover_first_name" :to="{
                                              name: 'client_employee_search',
                                              params: { id: client.u_id },
                                            }">
                                          <p>{{ client.name }}</p>
                                        </router-link>
                                      </td>
                                      <!-- <td><p class="email_tag">{{ client.workArea }}</p></td> -->
                                      <td align="center">
                                        <router-link class="text-dark" :to="{
                                              name: 'client_reports',
                                              params: { id: client.u_id },
                                            }">
                                            {{ client.reportsCount }}
                                        </router-link>
                                      </td>
                                      <td align="center">
                                        <router-link class="text-dark" :to="{
                                              name: 'client_requests',
                                              params: { id: client.u_id },
                                            }">
                                            {{ client.scheduleRequestCount }}
                                        </router-link>
                                      </td>
                                      <td align="center">
                                        <router-link class="text-dark" :to="{
                                              name: 'client_followups',
                                              params: { id: client.u_id },
                                            }">
                                            {{ client.followupsCount }}
                                        </router-link>
                                      </td>
                                      <!-- <td align="center"><p>{{ client.status }}</p></td> -->
                                      <td align="center" class="text-center">
                                        <div class="edit_btn">
                                          <p>
                                            <router-link class="text-dark" :to="{
                                              name: 'client_employee_search',
                                              params: { id: client.u_id },
                                            }">
                                              <img :src="require('@/assets/images/dashboard/View_Icon.svg')" alt="img" class="view-icon"/>
                                            </router-link>
                                            <!-- <a :href="'/user/client-employee-search/'+client.u_id" aria-current="page" class="view-dshboad-btn router-link-exact-active router-link-active" title="View">
                                              <img :src="require('@/assets/images/dashboard/View_Icon.svg')" alt="img" class="view-icon"/>
                                            </a> -->
                                            <!-- <a href="javascript:" aria-current="page" class="view-dshboad-btn router-link-exact-active router-link-active" title="View">
                                              <img :src="require('@/assets/images/dashboard/Edit_btn_table.svg')" alt="img" class="view-icon"/>
                                            </a> -->
                                          </p>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr v-if="data_length == 0">
                                      <td colspan="7" class="text-center">
                                        No Records found
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- cards  -->
                        
                        <div id="hash"  role="tabpanel" aria-labelledby="#hash" class="tab-pane fade">
                          <div class="Employee_search group_cards">
                            <div class="row px-4">
                              <div v-for="(client, indx) in clients_data" :key="indx" class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="white_card_box position-relative">
                                  <div class="form-check left-check">
                                    <!-- <input class="form-check-input" type="checkbox" value="" :id="'clientCheckbox'+indx"> -->
                                  </div>    
                                  <li class=" dropdown d-none d-lg-inline-block user three_dots">
                                    <a href="javascript:" id="navbarDropdownMenuLinkcd" role="button" data-bs-toggle="dropdown" aria-expanded="false" class="dropdown-toggle user-profile no-underline">
                                      <div class="dot"></div>
                                      <div class="dot"></div>
                                      <div class="dot"></div>
                                    </a>
                                    <ul aria-labelledby="navbarDropdownMenuLinkcd" class="dropdown-menu dropdown-menu-end view_dash_box">
                                      <li><a :href="'/user/client-program-overview/'+client.u_id" class="dropdown-item ">View Dashboard</a></li>
                                    </ul>
                                  </li>                                 
                                  <div class="person_info text-center p-4">
                                    <router-link :to="{
                                              name: 'client_employee_search',
                                              params: { id: client.u_id },
                                    }">
                                      <img :src="client.clientLogo">
                                      <!-- <img :src="require('@/assets/images/card_person.png')"> -->
                                      <h3 class="mt-2 Person_name">{{client.name}}</h3>
                                    </router-link>
                                  </div>
                                  <div class="card_info">
                                    <div class="row">
                                      <div class="col-6 col-sm-4 col-md-6 col-lg-4  py-3">
                                        <router-link :to="{
                                                  name: 'client_reports',
                                                  params: { id: client.u_id },
                                        }">
                                          <h6 class="text-center">{{client.reportsCount}}</h6>
                                          <p class="text-center">Reports</p>
                                        </router-link>
                                      </div>
                                      <div class="col-6 col-sm-4 col-md-6 col-lg-4 py-3">
                                        <router-link :to="{
                                                  name: 'client_requests',
                                                  params: { id: client.u_id },
                                        }">
                                          <h6 class="text-center">{{client.scheduleRequestCount}}</h6>
                                          <p class="text-center">Requests</p>
                                        </router-link>
                                      </div>
                                      <div class="col-12 col-sm-4 col-md-12 col-lg-4 py-3">
                                        <router-link :to="{
                                                  name: 'client_followups',
                                                  params: { id: client.u_id },
                                        }">
                                          <h6 class="text-center">{{client.followupsCount}}</h6>
                                          <p class="text-center">Follow-Ups</p>
                                        </router-link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div v-if="!clients_data.length" class="col-sm-12 col-md-12 col-lg-12">
                                <h3 class="text-center no_data card_no_data white_card border_radius_20">
                                  No Records found
                                </h3>
                              </div>
                            </div>
                          </div>  
                        </div>
                      </div>
                      <div class="px-4 mt_20">
                        <p class="float-lg-start text-sm-center">
                          {{ positionText }}
                        </p>
                        <VPagination v-if="data_length" class="dataTables_paginate sml_paginate" :totalPages="pages.length"
                          :perPage="itemsPerPage" :currentPage.sync="currentPage" @pagechanged="onPageChange" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import moment from "moment";
import "vue-select/dist/vue-select.css";
import commonFunction from "../../mixin/commonFunction";
import Datepicker from "vuejs-datepicker";
import SortIcons from "@/components/SortingIcons";
import VPagination from "@/components/VPagination";

export default {
  name: "management_client_manager",
  mixins: [commonFunction],
  components: {
    Datepicker,
    SortIcons,
    VPagination,
  },
  data() {
    return {
      show: false,
      clients_data: [],
      selected: true,
      isLoader: false,
      currentPage: 1,
      itemsPerPage: 10,
      listConfig: {
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
        sortBy: "company_name",
        order: "ASC",
        search_string: null,
        start_date: null,
        end_date: null,
        search_status: null,
        regionBy: null,
      },
      data_length: 0,
      isDisabled: "",
      addPermission: false,
      hasError: false,
      clearButton: true,
      disabledFutureDates: {
        from: new Date(Date.now()),
      },
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      status_list: [
        {
          id: null,
          name: "All Status",
        }, {
          id: "1",
          name: "Active",
        }, {
          id: "0",
          name: "Inactive",
        },
      ],
      region_list: [],
      previousStartDate: null,
    };
  },
  computed: {
    btnText: function () {
      if (this.show) {
        return "";
      }
      return "";
    },
    positionText: function () {
      var endIndex = this.currentPage * this.itemsPerPage,
        startIndex = (this.currentPage - 1) * this.itemsPerPage + 1;
      if (this.data_length == 0) {
        startIndex = 0;
      }
      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.data_length ? this.data_length : endIndex) +
        " of " +
        this.data_length
      );
    },
    pages() {
      if (this.data_length <= this.itemsPerPage) {
        return [1];
      }
      let pages_array = [
        ...Array(Math.ceil(this.data_length / this.itemsPerPage)).keys(),
      ].map((e) => e + 1);
      return pages_array;
    },
  },
  watch: {
    currentPage(newValue) {
      this.listConfig.offset = (newValue - 1) * this.itemsPerPage;
      this.getClients();
    },
  },
  destroyed() {
    this.$parent.employeeDashboard = false;
  },
  mounted() {
    this.$parent.employeeDashboard = true;
    document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
    this.base_url = process.env.VUE_APP_API_URL;
    this.listConfig = {
      offset: (this.currentPage - 1) * this.itemsPerPage,
      limit: this.itemsPerPage,
      sortBy: "company_name",
      order: "ASC",
      search_string: null,
      start_date: null,
      end_date: null,
      regionBy: null,
      search_status: null,
    };
    this.getClients();
    // if (this.$route.params.successMsg) {
    //   this.$toast.success(this.$route.params.successMsg, {
    //     position: "top-right",
    //     duration: 5000,
    //   });
    // }
    // if (this.$route.params.errorMsg) {
    //   this.$toast.error(this.$route.params.errorMsg, {
    //     position: "top-right",
    //     duration: 5000,
    //   });
    // }
    
  },
  beforeMount() {
    this.clientsForActivityRegion();
    // // this.$parent.$refs.fullpageloader.is_full_page_loader = true;
    // if (localStorage.getItem("userToken")) {
    //   window.addEventListener("scroll", this.scrollvue);
    //   // Store for check permission
    //   let res = this.$store.dispatch("auth/getAuthUser");
    //   res
    //     .then((value) => {
    //       let allPermission = value.user.roles.all_permission;
    //       if (allPermission.includes("users-edit")) {
    //         this.addPermission = true;
    //       }
    //       if (allPermission.includes("users-delete")) {
    //         this.deletePermission = true;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // } else {
    //   this.tokenExpired();
    // }
  },
  methods: {
    clientsForActivityRegion() {
      var _this = this;
      let config = {
        method: "get",
        url: process.env.VUE_APP_API_URL + "/api/clients-for-activity-region",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      };
      _this.axios(config)
        .then(({ data }) => {
          if (data.success) {
            var decodedJson = _this.decodeAPIResponse(data.records.data);
            
            _this.region_list.push({ id: 'ALL', name: 'ALL' });
            decodedJson.client_regions.forEach(function (items) {
              _this.region_list.push({ id: items.id, name: items.name });
            });
          }
          else {
            _this.region_list = [];
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    resetAll() {
      this.listConfig.end_date = "";
      this.listConfig.start_date = "";
      this.listConfig.regionBy = "";
      this.isDisabled = true;
      this.getClientsBysearch();
      this.getProductsBysearchReview();
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    sorting($event) {
      this.listConfig.sortBy == $event.sortBy;
      this.listConfig.order == $event.order;
      this.getClients();
    },
    getRegionfilterData() {
      this.getClientsBysearch();
      this.getProductsBysearchReview();
    },
    getClientsBysearch() {
      this.currentPage = 1;
      this.listConfig.limit = this.itemsPerPage;
      this.getClients();
    },
    getProductsBysearchReview() {
      this.currentPage_review = 1;
      this.listConfig_review.limit = this.itemsPerPage_review;
      this.listConfig_review.start_date_review = this.listConfig.start_date;
      this.listConfig_review.end_date_review = this.listConfig.end_date;
      this.listConfig_review.regionBy_review = this.listConfig.regionBy;
    },
    getClients() {
      var _this = this;
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/client-manager-pagination",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: {request_data : this.encodeAPIRequestWithImages(_this.listConfig)},
      };
      _this
        .axios(config)
        .then(({ data }) => {
          var decodedData = _this.decodeAPIResponse(data.data);
          _this.clients_data = decodedData.clients;
          _this.data_length = decodedData.counts;
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
        })
        .catch(({ response }) => {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          _this.$toast.error(response.data.error, {
            position: "top-right",
            duration: 5000,
          });
          if (response.data.type == false) {
            _this.tokenExpired();
          }
        });
    },

    updateDatePicker() {
      // Extend dataTables search
      $.fn.dataTable.ext.search.push(function (settings, data) {
        var min = $(".min-date-value").html();
        var max = $(".max-date-value").html();
        var createdAt = data[0] || 0; // Our date column in the table
        if (
          min == "" ||
          max == "" ||
          (moment(createdAt).isSameOrAfter(min) &&
            moment(createdAt).isSameOrBefore(max))
        ) {
          return true;
        }
        return false;
      });
      this.getClientsBysearch();
      this.getProductsBysearchReview();
    },
    // changeStartDate() {
    //   this.$nextTick(() => {
    //     this.isDisabled = false;
    //     this.disabledDates = {
    //       to: new Date(this.listConfig.start_date - 8640000),
    //       from: new Date(Date.now()),
    //     };
    //     if (
    //       this.listConfig.end_date != "" &&
    //       this.listConfig.end_date != null
    //     ) {
    //       this.updateDatePicker();
    //     }
    //   });
    // },
    changeStartDate(newStartDate) {
      this.$nextTick(() => {
        const startDate = new Date(newStartDate);
        const endDate = this.listConfig.end_date ? new Date(this.listConfig.end_date) : null;

        if (endDate && startDate > endDate) {
          this.$toast.error('"From" date cannot be greater than the "To" date.', {
              position: "top-right",
              duration: 5000,
            });
          this.listConfig.start_date = this.previousStartDate;
        } else {
          this.previousStartDate = newStartDate;

          // Old changeStartDate code
          this.isDisabled = false;
          this.disabledDates = {
            to: new Date(this.listConfig.start_date - 8640000),
            from: new Date(Date.now()),
          };
          if (this.listConfig.end_date != "" && this.listConfig.end_date != null) {
            this.updateDatePicker();
          }
        }
      });
    },
    changeEndDate() {
      this.$nextTick(() => {
        this.updateDatePicker();
      });
    },
    customFormatter(date) {
      return moment(date).format("MMM DD, YYYY");
    },
  },
};
</script>